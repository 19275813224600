'use client';

import { isClient } from '@/utils/is-client';
import { tap, share, buffer, filter, mergeAll } from 'rxjs/operators';
import { empty, concat, Subject, fromEvent, type Observable } from 'rxjs';

import { ARENA_APP_EVENT } from './types';

export * from './types';
export type { Observable } from 'rxjs';
export { tap, map, buffer, filter, bufferTime } from 'rxjs';

export interface Event {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
}

export type EventBus = Subject<Event>;
export const events$: EventBus = new Subject<Event>().pipe(
	filter(({ type }) => type !== ARENA_APP_EVENT), // Prevent infinite loop
	tap(
		({ type, value: detail }) =>
			isClient &&
			window.dispatchEvent(new window.CustomEvent(type, { detail })),
	),
	share(),
) as EventBus;

export const eventsFilterByType = (eventType: string) =>
	events$.pipe(filter((e: Event) => e.type === eventType));

export const eventsStartsWith = (eventPrefix: string) =>
	events$.pipe(filter((e: Event) => e.type.startsWith(eventPrefix)));

export const windowError$ = isClient ? fromEvent(window, 'error') : empty();
export const windowUnhandledRejections$ = isClient
	? fromEvent(window, 'unhandledrejection')
	: empty();

// buffer `src$` until `done$` emits, then emit buffered events and let `src$` emit freely
export const bufferUntil = <DoneEvent = unknown, SrcEvent = unknown>({
	done$,
	src$,
}: {
	done$: Observable<DoneEvent>;
	src$: Observable<SrcEvent>;
}) => concat(src$.pipe(buffer(done$), mergeAll()), src$);

/* eslint-disable prettier/prettier, no-console */
if (isClient) {
	window.addEventListener(
		ARENA_APP_EVENT,
		(
			{ detail }: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		) => events$.next(detail),
	);

	const searchParams = new URLSearchParams(document.location.search);

	if (searchParams.has('rvnEventsDebug')) {
		const eventsDebugSub = events$.subscribe((...args) => console.debug('rvnEvt', ...args));
	}

	if (searchParams.has('rvnPerformanceDebug')) {
		const rvnPerformanceDebugSub = events$.subscribe(({ type, value: detail }) =>{
			const name = `rvnMrk:${type}`;
			const mrk = window.performance.mark(name);
			console.debug(name, Math.trunc(mrk.startTime));
		});
	}


	if (searchParams.has('rvnPerformanceDebugStart') && searchParams.has('rvnPerformanceDebugEnd')) {
		const startParam = searchParams.get('rvnPerformanceDebugStart');
		const endParam = searchParams.get('rvnPerformanceDebugEnd');
		const rvnPerformanceMeasureSub = events$
			.pipe(
				filter(({ type }) => type === startParam || type === endParam),
			)
			.subscribe(({ type, value: detail }) => {
				const startMark = `rvnMsr:${searchParams.get('rvnPerformanceDebugStart')}`;
				const endMark = `rvnMsr:${searchParams.get('rvnPerformanceDebugEnd')}`;
				if (`rvnMsr:${type}` === startMark) {
					const mark = window.performance.mark(startMark);
					console.debug(startMark, Math.trunc(mark.startTime));
				} else if (`rvnMsr:${type}` === endMark) {
					const measureName = `${startMark}-${endMark}`;
					const mark = window.performance.mark(endMark);
					const measure = window.performance.measure(measureName, startMark, endMark);
					console.debug(
						measureName,
						' duration: ', Math.trunc(measure.duration),
						' startTime: ', Math.trunc(measure.startTime),
						' endTime: ', Math.trunc(mark.startTime),
					);
				}
			});
	}
}
/* eslint-enable */
